import React from 'react';
import ReactDOM from "react-dom";
import $ from 'jquery';
import cookie from 'react-cookies';
import { SetCurrentComponentName,showLockedBox, showConfirmBoxWithCancel, ShowToastMessage, IsApp, IsAndroid, IsIOS, IsAppTypePro, LoadGuestHandler, GetRedirectionURL,PSdateFormate } from "./RUtility";
import { hubConnection } from 'signalr-no-jquery';
//import { connect } from 'net';
import parse from 'html-react-parser';
import { StoreUserData } from './DataStore';
import { updateUserDataInCacheDB, getStore } from './app';
import { DataCreation } from './SocketAPI';
import { PrepareBookAssessment, HandleOffline,GetPaymentOrderId, clearUserRelatedData } from './RUtility';
var cartCount;
var currentPersonInLocal;
let connection = hubConnection('/signalr');
let publicHubProxy;
let apiCacheName = 'APICACHE';
var skipcount = 0;
var rootUrl = window.serverPath; //isPro ? "https://promobile.nihwn.co/" : "https://mobile.nihwn.co/";
var NIHWNCart = function () {
    // Add to Local Storage
    this.Add = function (providerId) {
        alert(providerId);
    };
}


function CheckandMakeConnectionHealthy(isSilent, callback) {
    var kHash = localStorage.getItem("validatormobilenumber-s");
    var vHash = null;
    var hmac;
    var clientType;
    try {
        vHash = cookie.load('validatorauth-s');

        if (!vHash) {
            vHash = localStorage.getItem("validatorauth-s");
        }    

        // console.log(token);
    } catch (e) {

        console.log("error in reading token from cookie...");
        console.log(e);
        vHash = localStorage.getItem("validatorauth-s");
    }

    hmac = localStorage.getItem("validatordevice-s"); // $("#validatordevice-s").val();   
    clientType = localStorage.getItem("validatorclienttype-s"); // $('#validatorclienttype-s').val();
    // manageOrgId = UtilClass.getValueFromLocalStorage("ManagedOrgId");
    clientType = clientType ? clientType : "WEB";

    if (kHash && vHash) {

        connection.qs = { "k": kHash, "v": vHash, "c": hmac, "ClientType": clientType };

    }
    isSilent = isSilent || true;

    if (connection.state == 1) {
        console.log("already connected");
        if (callback) {
            callback();
        }

        return;
    }

    // Not Connected, Reconnecting, Connecting.... Which means only Disconnected.
    if (!isSilent) {
        // enable 
        $(".establishing-connection-overlay").show();
    }
    var requestedDate = new Date();
    var isloaded = false;

    fconnectionEstablish(callback);

}
function fconnectionEstablish(callback) {
    try {

        var kHash;
        var vHash;
        var hmac;
        var clientType;
        var lrt = getLSValueByKey("LastSyncDataTime");
        kHash = getLSValueByKey("validatormobilenumber-s");
        if (!kHash) {
            kHash = getFromCookie("validatormobilenumber-s");
        }
        vHash = null;
        try {
            vHash = cookie.load('validatorauth-s'); //$.cookie("validatorauth-s");
            if (!vHash) {
                vHash = localStorage.getItem("validatorauth-s");
            }    
        } catch (e) {
            vHash = getLSValueByKey("validatorauth-s");
        }

        hmac = getLSValueByKey("validatordevice-s"); // $("#validatordevice-s").val();   
        clientType = getLSValueByKey("validatorclienttype-s"); // $('#validatorclienttype-s').val();

        clientType = clientType ? clientType : "WEB";

    } catch (e) {
        console.log(e);
    }

    if (kHash && vHash) {
        connection.qs = { "k": kHash, "v": vHash, "c": hmac, "ClientType": clientType, "ManagerOrganisationId": "", "LRT": lrt };
        publicHubProxy = connection.createHubProxy('bILPLCommEngine');
    }
    else {
        publicHubProxy = connection.createHubProxy('publicEngine');
    }

    if (this) {
        this.publicHubProxy = publicHubProxy;
        this.connection = connection;
    }

    connection.logging = true;
    connection.start().done(function () {

        if (callback) {
            callback();
        }
    })
        .fail(function () { console.log('Could not connect'); });;
    connection.error(function (error) {
        console.log(error);
    });


    connection.stateChanged(function (change) {
        console.log('state changed');
    });


    connection.reconnected(function () {
        console.log('Reconnected');
    });

}
var loadedData = null;
var dataFiltered = {};



function getFromCookie(type) {
    try {
        var result = cookie.load(type);
        return result;
    } catch (e) {
        console.log(e);
    }
}
//var currentShareVal = "";
//function getShareData() {
//    alert("Hello Android! From mlayout");
//    try {
//        return currentShareVal;
//    } catch (e) {
//        console.log(e);
//    }
//}

let UpdateUserDetails;
let ProcessDashboardData;
let connectionEstablish;
export const getUserTokenMethod = () => {
    return getUserToken();
}
//export const CheckandMakeConnectionHealthyMethod = (isSilent, callback) => {
//    return CheckandMakeConnectionHealthy(isSilent, callback);
//}





export const GetPersonByMobileNumber = (callback) => {
    getPersonByMobileNumber(callback);
}

let selfObj;


var OrdersDataFiltered;
var dispatch;
var SelectedFacility;
export default class MLayout extends React.Component {

    constructor(props) {

        //console.log(loadedData);

        super(props);
        try {
            SetCurrentComponentName("MLayout");
          }
          catch {
          }

        selfObj = this;
        window.MInstances = this;
        var apiCacheName = 'APICACHE';

        this.state = { Language: "", MasterData: [], UserData: {}, LoveData: [], BookMarksData: [] };

        this.SelectedFacility = SelectedFacility;
        this.connection = null;
        this.publicHubProxy = null;
        this.AnalyticsList = null;
        

        connectionEstablish = this.connectionEstablish.bind(this);
        //var response = localStorage.getItem("MasterData");
        //if (response) {
        //    var repos = JSON.parse(response);

        //    this.state = { MasterData: repos, UserData: {} };

        //    if (currentPersonInLocal) {
        //        //  this.state = { IsDataLoaded: true, MSD: repos.MasterData, GL: repos.GList, US: repos.USettings, CartCount: 0, currentPersonDetails: currentPersonInLocal, DashboardUserDataList: [], FamilyMembersDataObj: [] };
        //        this.state = { IsDataLoaded: true, MasterData: repos, currentPersonDetails: currentPersonInLocal };
        //    }
        //    else {
        //        this.state = { IsDataLoaded: true, MasterData: repos, UserData: {} };
        //    }

        //    this.ManageUserAggregates();


        //}
        //else {
        //    this.state = { Language: "", MasterData: [], UserData: {} };
        //    //this.sync();
        //}
        //try {
        //    //const newCache = await caches.open(apiCacheName);
        //    //const response = await newCache.match("/Parameter");
        //    //if (response) {
        //    //    var resultObj = await response.json();
        //    //    selfObj.setState({ PD: resultObj.MasterData });
        //    //}

        //    caches.open(apiCacheName).then(cache => {
        //        cache.match("/UserData").then(async userDataResponse => {

        //            //console.log(response);
        //            if (userDataResponse) {
        //                var resultObj = await userDataResponse.json();
        //                MInstances.setState({ UserData: resultObj });
        //                MInstances.ManageUserData(selfObj.state.currentRole);
        //            }
        //        });
        //    });
        //} catch (e) {
        //    console.log(e);
        //}
        try {

            this.kHash = localStorage.getItem("validatormobilenumber-s");
            if (!this.kHash) {
                this.kHash = getFromCookie("validatormobilenumber-s");
            }

            this.vHash = getUserToken();

        } catch (e) {
        }
        
        var allowedRoles = ["Owner","FacilityOwner","Investor","Manager","Harvest","HarvestAdmin","BDC","NCC","Receptionist","ChiefTherapist","Senior","Therapist","CCC","ClarityContributer","Boss","Lead","Security"];
        var leaderRoles = ["receptionist", "manager", "owner", "ccc", "harvestadmin", "facilityowner","ncc"];
        try {
            var roles = localStorage.getItem("UserRoles");

            if(roles == "undefined"){
                roles = JSON.stringify("Lead");
            }
            //console.log("roles");
            if (!roles) {

                var Croles = getFromCookie("UserRoles");
                if (Croles) {
                    roles = JSON.stringify(Croles);
                    localStorage.setItem("UserRoles", roles);
                }
            }
            var token = localStorage.getItem("validatorauth-s");
            //console.log("roles");
            if (!token) {

                token = getFromCookie("validatorauth-s");
                if (token) {                 
                    localStorage.setItem("validatorauth-s", token);
                }
            }
        
            roles = JSON.parse(roles);
            if (roles) {
                this.UserRoles = $.grep(allowedRoles, function (e) { return roles.indexOf(e) > -1 });
                //console.log(this.UserRoles);
                if (!this.UserRoles || this.UserRoles.length == 0) {
                    this.IsUserLead = true;
                }
                if (roles.indexOf('CCC') > -1 || roles.indexOf('NCC') > -1) {

                    this.IsUserNCC = true;
                }

                if(roles.indexOf("BDC") >-1)
                {
                    this.IsUserBDC = true;
                }
                if (roles.indexOf('Receptionist') > -1) {
                    this.IsUserAdmin = true;
                }
                if(roles.indexOf('ClarityContributer')>-1)
                {
                    this.IsUserClarityContributor = true;
                }
                if (roles.indexOf('Owner') > -1 || roles.lastIndexOf("Manager") > -1) {
                    this.IsUserOwner = true;
                }

                if (roles.indexOf('FacilityOwner') > -1) {
                    this.IsUserFacilityOwner = true;
                }
                if (roles.indexOf('Investor') > -1) {
                    this.IsUserInvestor = true;
                }
                // if (roles.indexOf('Manager') > -1) {
                //     this.IsUserManager = true;
                // }
                if (roles.indexOf('Senior') > -1) {
                    this.IsUserSenior = true;
                }
                if (roles.indexOf('Therapist') > -1) {
                    this.IsUserTherapist = true;
                }
                if (roles.indexOf('Boss') > -1) {
                    this.IsUserBoss = true;
                }
                if (roles.indexOf('Lead') > -1) {
                    this.IsUserLead = true;
                }
                if (roles.indexOf('Security') > -1) {
                    this.IsUserSecurity = true;
                }
if(roles.indexOf("Harvest") > -1)
{
this.IsUserHarvest = true;
}
                if (this.UserRoles.some(r => leaderRoles.includes(r.toLowerCase()))) {
                    this.IsLeader = true;
                }
            }
        } catch (e) {
            console.log(e);
        }


        try {

            this.connection = connection;
            this.publicHubProxy = publicHubProxy;
            //console.log(this.connection);


        } catch (e) {
            console.log("error in connection " + e);
        }

    }

    setUserData(userDataResponse) {
        if (userDataResponse) {

            this.setState({ UserData: userDataResponse });

            // this.ManageUserData(selfObj.state.currentRole);
        }
    }
    getFromCookie(type) {
        getFromCookie(type);
    }






    GetInitialData(callback) {
        try {
            caches.open(apiCacheName).then(cache => {
                cache.match("/PSUserData").then(async userDataResponse => {

                    //console.log(response);
                    if (userDataResponse) {
                        var resultObj = await userDataResponse.json();
                        window.MInstances.setState({ UserData: resultObj });
                        if (callback) {
                            callback();
                        }
                    }
                });
            });
        } catch (e) {

        }
    }


    isToday(someDate) {
        const today = new Date()
        return someDate.getDate() == today.getDate() &&
            someDate.getMonth() == today.getMonth() &&
            someDate.getFullYear() == today.getFullYear()
    }





    GetGeoCountries() {

        const apiUrl = window.root + 'api/getmasterdata?lsd=';

        fetch(apiUrl)
            .then((res) => res.json())
            .then((repos) => {

                //console.log("START :: Data STORE LOCAL CACHE");
                //logDateTime();

                if (repos && repos.MasterData.length > 0) {

                    //MERGE WITH OLD DATA
                    localStorage.setItem("MasterData", JSON.stringify(repos));

                    //localStorage.setItem("LastPSSyncDataTime", repos.LastSyncDataTime);

                    this.setState({ IsDataLoaded: true, MSD: repos.MasterData, GL: repos.GList, US: repos.USettings });
                    loadedData = repos.USettings;
                }
            });
    }






    GetPersonDetailsFromLocalStorage() {
        var user = localStorage.getItem("UserData");
        this.currentPersonDetails = JSON.parse(user);
    }

    TellaFriend() {

        var title = "Life Skills, Social Skills, Emotional Skills, Cognitive Skills can enable your kid to be self-sufficient, to be part of mainstream society, to stand chance at life, career, family, have wonderful life. Bring smiles into your family. \n\n" +
            "8,50,000 + 1:1 exclusive therapy services with 97% + proven improvement, 96% + parental satisfaction promising to do everything plausible for your kid to be self - sufficient, to be part of mainstream society, to stand chance at life, career, family, have wonderful life.Bring smiles into your family. \n\n" +
            "For Speech, ABA, OT, SE & Self Assessments.. Download app:https://mirracle.pinnacleblooms.org/dl \n\n\n" +
            "https://pinnacleblooms.org \n#1 Autism Therapy Centres Network, \n\n" +
            "#1 Multi-Senory, Multi-Disciplinary, Integrated Autism Therapy Centres Network. \n\n" +
            "CALL OUR FREE NATIONAL AUTISM HELPLINE for anything/ everything about autism. \n\n" +
            "WhatsApp: https://wa.me/+919100181181"

        //(!isNullOrEmpty(this.currentPersonDetails.Name) ? this.currentPersonDetails.Name : "We cordially") + " Pinnacle Blooms ™ provide practical hands - on, real - world oriented, multi sensory & child centered\n transparent approach... for all Speech, Language disorders & reading, writing, cognitive Social, \n difficulties at all stages of child.\n\n Link: https://mirracle.pinnacleblooms.org/";
        var app = this.getapptype();

        if (IsIOS(app)) {
            window.location.href = "mirraclescheme://sharecallback=" + title;
            return;
        }

        if (navigator.share === undefined) {
            //console.log('Undefined!');

            window.currentShareVal = title;

            if (IsAndroid(app)) {
                window.location.href = "mirraclescheme://sharecallback";
            }
            else {
                alert("Sharing is not available for your browser currently, we will enable it soon. You may still share via WhatsApp.");
            }
        }
        else {
            //console.log('Sharing!');

            navigator.share({ title: title, text: title });
        }
    }

    showStatusBar(message) {
        //alert(message);
        ShowToastMessage(message, 15000);
    }

    getUserRole() {
        try {
            var jsonString = localStorage.getItem("UserRoles");

            if (jsonString) {
                var rolesList = JSON.parse(jsonString);
                var role = rolesList.some(function (e) { return e.toLowerCase() == "ccc" }) ? "NCC" :
                    rolesList.some(function (e) { return e.toLowerCase() == "receptionist" }) ? "Receptionist" : rolesList.some(function (e) { return e.toLowerCase() == "manager" }) ? "Manager" : rolesList.some(function (e) { return e.toLowerCase() == "owner" }) ? "Owner" : rolesList.some(function (e) { return e.toLowerCase() == "facilityowner" }) ? "FacilityOwner" : rolesList.some(function (e) { return e.toLowerCase() == "chieftherapist" }) ? "chieftherapist" : rolesList.some(function (e) { return e.toLowerCase() == "therapist" }) ? "therapist" : "boss";

                return role;
            }
            else {
                return "boss";
            }
        } catch (e) {
            console.error("error in reading clientype from local storage...");
            console.log(e);

            return "";
        }
    }

    getapptype() {
        try {
            var clientType = localStorage.getItem("validatorapptype-s");
            if (!clientType) {
                clientType = getFromCookie("validatorapptype-s");
            }
            //console.log(clientType);
            return clientType;
        } catch (e) {
            console.log("error in reading clientype from local storage...");
            console.log(e);
        }
    }

    getproapp() {
        try {
            var appType = IsAppTypePro();
            //console.log(clientType);
        } catch (e) {
            console.log("error in reading app type from local storage...");
            console.log(e);
        }

        return appType;
    }


    getMobileOperatingSystem() {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;
        // Windows Phone must come first because its UA also contains "Android"
        if (/windows phone/i.test(userAgent)) {
            return "Windows Phone";
        }
        else if (/android/i.test(userAgent)) {
            return "Android";
        }

        // iOS detection
        else if (/iPad|iPhone|iPod/.test(userAgent)) {
            return "iOS";
        }

        return "unknown";
    }

    connectionEstablish(callback) {
       // connectionEstablish();
    }

    getPersonByMobileNumber(callback) {
        getPersonByMobileNumber(callback);
    }

    createOrUpdateSignupUser(user, issignup, RoleId, callBack) {

        var self = this;
        CheckandMakeConnectionHealthy(true, function () {
            publicHubProxy.invoke("createOrupdatePersonfromProfile", user, issignup).done(function (result) {
                if (!isNullOrEmpty(result)) {
                    this.currentPersonDetailsToSave = result;
                    this.currentPersonDetails = result;

                    localStorage.setItem("UserData", JSON.stringify(result));



                    this.IsSignup = false;
                    this.currentPersonDetailsToSave = result;

                    //removing popUpBackround class.

                    if (callBack) {
                        callBack(result);
                    }

                    window.historyInstance.goBack();
                    setTimeout(function () {
                        $('html, body').removeClass('popUpBackround');

                        window.historyInstance.push("/d");

                        localStorage.setItem("currentPersonDetailsToSave", null);
                    }, 600);



                    this.IsSaveUserRequestSubmitted = false;

                }
            }).fail(function (result) {
                console.log(result);
                $('.submit-wrapper').removeClass('is-saving');
                $(".signup-loader .continue-set").removeClass("changeloadbtn");
                showLockedBox(result, '', 'OK', true);
            });
        });
    }

    //updateState = function () {
    //    console.log("updateState");
    //    console.log(this);
    //}
    GetRedirectionURL(Htype, user, role, openUrl,fid) {
        fid = fid || null;
        if (Htype == "mirraclesnotcovered") {
         
            var languages = $.grep(user.ALAN.toLowerCase().split(","), function (e) { return user.LAN.toLowerCase().indexOf(e) == -1 });
            if (languages && languages.length > 0) {
                alert(languages.join("\n"));
            }
            return;
        }

        GetRedirectionURL(Htype, user, role, openUrl, fid)
    }
    ShowGuestHandler(url, isAuthNeeded,title) {
        LoadGuestHandler(url, isAuthNeeded, title);
    }

    //This method for getting users roles from facilities roles

    MakeRazorpay(activity, callback) {
        try {
            //console.log("Make RZP");

            var paymentAmount = activity.F7;
            var paidAmount = 0;
            var amtTobepaid = activity.F7;
            if (activity.Payments && activity.Payments.length > 0) {
                paidAmount = activity.Payments.reduce((accumulator, current) => accumulator + current.F7, 0);
                amtTobepaid = amtTobepaid - paidAmount;
            }
            var currentPerson = selfObj.props.currentPersonDetails;
            if (this.props && this.props.currentPersonDetails) {
                currentPerson = this.props.currentPersonDetails; 
            }
            var selfthis = this;
            if (amtTobepaid > 0) {
                // amtTobepaid = 1;
                CheckandMakeConnectionHealthy(true, function () {
                    publicHubProxy.invoke('getPaymentOrderId', amtTobepaid).done(function (orderId) {

                        //console.log('server order id');
                        //console.log(orderId);
                        $(".svg.solid").removeClass("changeloadbtn");
                        InitiateRazorPay(orderId, activity.RDT, amtTobepaid, activity, currentPerson, selfObj, function (resultback) {
                            try {
                                if (callback) {
                                    callback(resultback);
                                }
                            } catch (e) {
                                console.log(e);
                                $(".svg.solid").removeClass("changeloadbtn");
                                showLockedBox("", "", "ok");
                            }
                        },null);
                    });
                });
            }
        } catch (e) {
            console.log(e);
        }
    }

    MakeRazorpayWithAmount(amtTobepaid, callback,cancelCallback) {
        try {
            //console.log("Make RZP");

            var currentPerson = selfObj.props.currentPersonDetails;
            if (amtTobepaid > 0) {
                // amtTobepaid = 1;
                GetPaymentOrderId(amtTobepaid,function(orderId){
                    InitiateRazorPay(orderId, currentPerson.RDT, amtTobepaid, null, currentPerson, selfObj, function (amount,resultback) {
                        try {
                            if (callback) {
                                callback(amount,resultback);
                            }
                        } catch (e) {
                            console.log(e);
                            $(".svg.solid").removeClass("changeloadbtn");
                            showLockedBox("", "", "ok");
                        }
                    }, cancelCallback);
             
                });
                // CheckandMakeConnectionHealthy(true, function () {
                //     publicHubProxy.invoke('getPaymentOrderId', amtTobepaid).done(function (orderId) {

                //         //console.log('server order id');
                //         //console.log(orderId);
                //         $(".svg.solid").removeClass("changeloadbtn");

                //         InitiateRazorPay(orderId, currentPerson.RDT, amtTobepaid, null, currentPerson, selfObj, function (amount,resultback) {
                //             try {
                //                 if (callback) {
                //                     callback(amount,resultback);
                //                 }
                //             } catch (e) {
                //                 console.log(e);
                //                 $(".svg.solid").removeClass("changeloadbtn");
                //                 showLockedBox("", "", "ok");
                //             }
                //         }, cancelCallback);
                //     });
                // });
            }
        } catch (e) {
            console.log(e);
        }
    }

    payOfferOnlineWithAmount(activity, callback, cancelCallback) {
        selfObj.MakeRazorpayWithAmount(activity, function (amount,result) {

            if (result) {
                callback(amount,result);
            }
        }, cancelCallback);
    }

    payOnline(activity,callback) {
        selfObj.MakeRazorpay(activity, function (result) {
            if (result) {
                var person = selfObj.props.currentPersonDetails;
                var status = "";
                try {

              
                if (activity.Payments) {
                    var amtPaid = activity.Payments.reduce((accumulator, current) => accumulator + current.F7, 0);
                    amtPaid += result;
                    if (amtPaid < activity.F7) {
                        status = "PartiallyPaid";
                    }
                    else {
                        status = "Paid";
                    }
                }
                else if (activity.F7 > parseInt(result)) {
                    status = "PartiallyPaid";
                }
                else if (parseInt(result) >= activity.F7) {
                    status = "Paid";
                    }
                } catch (e) {

                }
                var paymentAna = {};
                paymentAna.AT = activity.AT == "ROYALTY" ? "ROYALTYPAYMENT" : activity.AT == "TALENTACQUISITION" ? "TAPAYMENT" : "Payment";
                paymentAna.RDT = activity.RDT;
                paymentAna.S = status;
                paymentAna.F1 = activity.F1;
                paymentAna.F2 = activity.F2 ? activity.F2 : "";
                paymentAna.F3 = activity.F3;
                paymentAna.F6 = "Test";
                paymentAna.F4 = "Online";
                if (activity.AT == "ROYALTY" || activity.AT == "TALENTACQUISITION") {
                    paymentAna.F5 = "With GST";
                }
                paymentAna.F7 = result;
                paymentAna.TOId = activity.TOId;
                paymentAna.CBPI = person.OGID;
                paymentAna.CBPN = person.RDT;
                paymentAna.FId = activity.FId;
                paymentAna.MG = activity.MG;
                paymentAna.AG = activity.AG;
                paymentAna.RDUId = [];
                paymentAna.RDUId.push(parseInt(activity.UId));
                //console.log(JSON.stringify(paymentAna));
                DataCreation(paymentAna, function (res, error) {
                    if (!error) {
                        var resData = {
                            FData: res,
                        };
                        callback(true);
                        //console.log(res);
                        updateUserDataInCacheDB(resData, function (data) {
                            selfObj.props.dispatch(StoreUserData(data));
                            selfObj.props.ManageUserData("", "", "");
                            $(".svg.solid[data-id=" + activity.UId + "]").removeClass("changeloadbtn");
                        });
                    } else {

                        callback(false);
                        showLockedBox(error.ErrorMsg, "Error", "ok");
                        // console.error(error.StackTrace);
                    }
                });
            }
            else {
                callback(false);
            }
        });
    }


    UpdateLang(lang) {
        lang = !isNullOrEmpty(lang) ? lang : "english";
        //console.log(lang);
        $(".setting-lang span").removeClass('activespan');
        $(".tag-name").removeClass('activelang');
        $(".change-lang span").removeClass('activelang');
        $(".change-lang p").removeClass('activelang');
        $("." + lang + "-lang").addClass('activelang');
        $("#" + lang).addClass('activespan');
    }

    ChangeSettingsLang(lang) {
        //console.log(lang);
        this.state.Language = lang;
        this.setState({ Language: lang });
        localStorage.setItem("Language", lang);
        this.Language = lang;
        window.historyInstance.goBack();
        $(".setting-lang span").removeClass('activespan');
        $(".tag-name").removeClass('activelang');
        $(".change-lang span").removeClass('activelang');
        $(".change-lang p").removeClass('activelang');
        $("." + this.state.Language + "-lang").addClass('activelang');
        $("#" + this.state.Language).addClass('activespan');
        $('html, body').removeClass('popUpBackround');
    }

    UpdateUserProfile(user, isSignUp, roleId, callBack) {
        try {
            SaveUserProfileInServer(user, isSignUp, roleId, callBack);
        } catch (e) {
            console.log("Err::UpdateUserProfile()" + e);
        }
    }

    clearUserRelatedData1() {
        try {

            var cType = getLSValueByKey("validatorclienttype-s");
            if (isNullOrEmpty(cType) || cType == "null")
                cType = "";

            var appCType = this.getapptype();

            localStorage.removeItem("Role");
            localStorage.removeItem("UserRoles");
            clearLocalStorage();
            cookie.remove("validatorauth-s");
            cookie.remove("validatormobilenumber-s");
            cookie.remove("UserRoles");
            try {
                cookie.remove('validatorauth-s', { path: "/", domain: "pinnacleblooms.org" });
                cookie.remove('validatormobilenumber-s', { path: "/", domain: "pinnacleblooms.org" });
                cookie.remove('UserRoles', { path: "/", domain: "pinnacleblooms.org" });
            } catch (e) {

            }
            try {
                if ('caches' in window) {
                    caches.keys()
                        .then(function (keyList) {
                            return Promise.all(keyList.map(function (key) {
                                return caches.delete(key);
                            }));
                        })
                }
            } catch (e) {
                console.log(e);
            }

            if (IsIOS(appCType)) {
                window.location.href = "mirraclescheme://logoff";
            }
            setTimeout(function () {
                window.location.href = "/" + (appCType ? "?ct=" + appCType : "");
            }, 20);

        } catch (e) {
            console.log("Error in clearUserRelatedData : " + e);
        }
    }


    logOff() {
        //alert("log off called");
        //if (!IsOnline) {
        //   // UtilClass.ShowToastMessage("You can't logoff now, Please do connect to internet");
        //    return;
        //}

        showConfirmBoxWithCancel("Are you sure you want to logoff?", "Info", "Cancel{#}Proceed", false, function (callback) {
            if (!callback) {
                clearUserRelatedData();
            }
        });
    }




    ShowShare(activity, type) {
        var sTitle = FormatStringForUrl(activity.SN && activity.SN != "" ? activity.SN : activity.Title);
        var title = activity.Title;
        var pro = this.getproapp();
        switch (type) {
            case "wall":
                sTitle = FormatStringForUrl(activity.SN && activity.SN != "" ? activity.SN : activity.Title) + "/" + activity.Id;
                break;
            case "test":
                sTitle = "?" + FormatStringForUrl(activity.SN && activity.SN != "" ? activity.SN : activity.RT);
                title = activity.RT;
                break;
            default:
                break;
        }
        var url = rootUrl + sTitle;
        //console.log(sTitle);
        //console.log(url);
        title = "NIHWN.Co\n\n" + title + "\n\n" + url + "\n\nDownload app:\n" + rootUrl + "dl";
        var app = this.getapptype();
        //console.log(title);

        if (IsIOS(app)) {
            window.location.href = "mirraclescheme://sharecallback=" + title;
            return;
        }
        if (navigator.share === undefined) {
            //console.log('Undefined!');
            // var apptype = localStorage.getItem("apptype");
            if (IsAndroid(app)) {
                // alert("handle android");
                window.currentShareVal = title;
                window.location.href = "mirraclescheme://sharecallback";

            }
            else {


                alert("Sharing is not available for your browser currently, we will enable it soon. You may still share via WhatsApp.");
            }
        }
        else {
            console.log('Sharing!');

            navigator.share({ title: title, text: title });
        }




    }

    closeInner(fromPage, fromTitle) {

        //this.state.showInnerDetails
        this.setState({ showInnerDetails: false });

        if (fromPage) {
            // window.history.pushState("", fromTitle, fromPage);
        }
    }

    componentDidMount() {


console.log("mlayout")

        this.handleConnectionChange();
        window.addEventListener('online', this.handleConnectionChange);
        window.addEventListener('offline', this.handleConnectionChange);
    }


    //componentWillUnmount() {
    //    window.removeEventListener('online', this.handleConnectionChange);
    //    window.removeEventListener('offline', this.handleConnectionChange);

    //}


    OpenTheUrlinWeb(url) {
        var app = this.getapptype();
        var userAgent = this.getMobileOperatingSystem();

        var isProType = IsAppTypePro();

        if (IsApp(app)) {
            setTimeout(function () {

                window.location.href = "mirraclescheme://openinweb=" + url;
            }, 100);
        }
        else {
            window.location.href = url;
        }
    }

    makeUrlSchemeCallforIOS() {
        var app = this.getapptype();
        //  var userAgent = this.getMobileOperatingSystem();

        var isProType = IsAppTypePro();

        if (IsIOS(app)) {
            setTimeout(function () {
                window.location.href = "mirraclescheme://imagepickeropen";
            }, 100);
        }
    }

    callIOSImageClose() {
        var app = this.getapptype();
        var userAgent = this.getMobileOperatingSystem();

        var isProType = IsAppTypePro();
        // alert(app);
        if (IsIOS(app)) {
            setTimeout(function () {
                window.location.href = "mirraclescheme://imagepickerclose";

                //document.body.onfocus = null;
            }, 100);
        }
    }


    handleConnectionChange = () => {
        const condition = navigator.onLine ? 'online' : 'offline';
        if (condition === 'online') {
            try {

                return this.setState({ isDisconnected: false });
            } catch (e) {

            }
        } else {
            return this.setState({ isDisconnected: true });
        }
    }














    getGeoCountries(callBack) {
        try {
            CheckandMakeConnectionHealthy(true, function () {
                publicHubProxy.invoke('GetAllCountriesAsync').done(function (result) {
                    try {
                        if (result) {
                            sessionStorage.setItem("GeoCountries", JSON.stringify(result));

                            if (callBack) {
                                callBack(result);
                            }
                        }
                    } catch (e) {
                        console.log(e);
                    }

                }).fail(function (f) {
                    if (IsSignalrConnectionFailed(f)) {
                        HandleConnectionFailure(function () {
                            selfObj.getGeoCountries(callBack);
                        });
                    }
                    else {
                        console.log("failed");
                        console.log(f);
                    }
                });
            });
        } catch (e) {
            console.log("Error in getGeo countries.");
        }
    }


    checkandMakeConnectionHealthy(isSilent, callback) {
        CheckandMakeConnectionHealthy(isSilent, callback);
    }

    isSignalrConnectionFailed(exception) {
        return IsSignalrConnectionFailed(exception);
    }

    handleConnectionFailure(callback) {
        HandleConnectionFailure(callback);
    }

    RequestForFeedback(act, isFromCommon) {
        
        try {
            if (!act) {
                return;
            }
            
            var d1Data=this.props.UserData;
            var currentUser = d1Data.CFUser;
            var sessionData = act.Session;
            var type=d1Data.Types.filter(e=>e.Name=="FEEDBACK")[0];
            // $(".cm-fsubmit-class .submit-wrapper").addClass('is-saving');
            // var requestFBAna = {};
            // if (isFromCommon) {
            //     requestFBAna.CBPI = act.TId;
            //     requestFBAna.CBPN = act.TN;
            //     requestFBAna.RDUId = [parseInt(act.UId)];
            //     requestFBAna.TOId = act.BId;
            //     requestFBAna.F3 = act.BN;
            //     requestFBAna.F5 = act.ST;
            // } else {
            //     requestFBAna.CBPI = act.CBPI;
            //     requestFBAna.CBPN = act.CBPN;
            //     requestFBAna.FId = act.FId;
            //     requestFBAna.RDUId = [parseInt(act.UId)];
            //     requestFBAna.TOId = act.TOId;
            //     requestFBAna.F3 = act.F3;
            //     requestFBAna.F5 = act.F5;
            // }

            // requestFBAna.AT = "RequestForFeedback";
            // requestFBAna.FId = act.FId;
            // requestFBAna.AG = act.AG;
             var requestObj={};
             requestObj.CBPI = currentUser.Id;
             requestObj.TOId = sessionData.BossId;
             requestObj.F1 = act.Id+"";
             requestObj.CDT = PSdateFormate(new Date());
             requestObj.TypeId = type.Id;
             
            //console.log(JSON.stringify(requestFBAna));
            DataCreation(requestObj, function (res, error) {
                if (!error) {
                    //console.log(JSON.stringify(res));
                    var resData = {
                        Data: res,
                    };
                   // updateUserDataInCacheDB(resData, function (result) {
                     //   getStore().dispatch(StoreUserData(result));
                        showLockedBox("Successfully Requested for feedback", "Info", "ok");
                    //});
                } else {
                    showLockedBox(error.ErrorMsg, "Error", "ok");
                    console.error(error.StackTrace);
                }

                $(".cm-fsubmit-class .submit-wrapper").removeClass('is-saving');
            },null,"REQUESTFORFEEDBACK");
        } catch (e) {
            console.error(e);
        }
    }


    render() {
        return <div></div>
    }
}


export const saveUserProfileInServer = (user, isSignUp, roleId, callBack) => {
    try {
        SaveUserProfileInServer(user, isSignUp, roleId, callBack);
    } catch (e) {

    }
}



export const ShowShareCall = (activity, type) => {
    try {
        ShowShare(activity, type);
    } catch (e) {

    }
}

export const SaveRecordAnalytics = (analytics, callback) => {
    try {
        // RecordAnalytics(analytics, function (result) {
        //     //console.log("======result=======");
        //     if (callback) {
        //         callback(result);
        //     }
        //     //console.log(result);
        //     return result;
        // });
    } catch (e) {
        console.log(JSON.stringify(e));
    }
}



export const CreateTempGuid = () => {
    try {
        return CreateGuid();
    } catch (e) {
        console.log(JSON.stringify(e));
    }
}

function getUserToken() {
    var token = ""
    try {
        token = cookie.load('validatorauth-s');

        if (!token) {
            token = localStorage.getItem("validatorauth-s");
        }       
    } catch (e) {

        console.log("error in reading token from cookie...");
        console.log(e);
        token = localStorage.getItem("validatorauth-s");
    }

    return token;
}

function ShowShare(activity, type) {
    var sTitle = FormatStringForUrl(activity.SN && activity.SN != "" ? activity.SN : activity.Title);
    var title = activity.Title;
    var description = activity.Desc && activity.Desc.length > 0 ? parse(activity.Desc.substring(0, 180)) : ""

    var pro = IsAppTypePro();
    switch (type) {
        case "wall":
            sTitle = FormatStringForUrl(activity.SN && activity.SN != "" ? activity.SN : activity.Title) + "/" + activity.Id;
            break;
        case "test":
            sTitle = "?" + FormatStringForUrl(activity.SN && activity.SN != "" ? activity.SN : activity.RT);
            title = activity.RT;
            description = activity.RD && activity.Desc.length > 0 ? parse(activity.RD.substring(0, 180)) : ""

            break;
        case "reportssharing":
            sTitle = "?" + FormatStringForUrl(activity.SN && activity.SN != "" ? activity.SN : activity.RT);
            title = "Pelase download report for " + activity.RT;
            description = activity.RD && activity.RD.length > 0 ? parse(activity.RD.substring(0, 180)) : ""

            break;
        default:
            break;
    }

    var url = rootUrl + "/updates/" + sTitle;
    if (type == "reportssharing" && activity.OrderAllReportsReady) {
        url = activity.OrderAllReportsReady.Data3 ? activity.OrderAllReportsReady.Data3 : activity.OrderAllReportsReady.Data2;
    }

    //console.log(url);
    title = title + "\n\n" + (description ? description + "..." + "\n\n" : "") + url + "\n\nDownload app:\n" + rootUrl + "dl";
    var app = localStorage.getItem("validatorapptype-s");
    //console.log(title);

    if (IsIOS(app) && pro) {
        window.location.href = "pronihwnscheme://sharecallback=" + title;
        return;
    }
    else if (IsIOS(app)) {
        window.location.href = "nihwnscheme://sharecallback=" + title;
        return;
    }

    if (navigator.share === undefined) {
        //console.log('Undefined!');

        // var apptype = localStorage.getItem("apptype");
        if (IsAndroid(app) && pro) {
            // alert("handle android");
            window.currentShareVal = title;
            window.location.href = "pronihwnscheme://sharecallback";

        }
        else if (IsAndroid(app)) {
            // alert("handle android");
            window.currentShareVal = title;
            window.location.href = "nihwnscheme://sharecallback";

        }
        else {


            alert("Sharing is not available for your browser currently, we will enable it soon. You may still share via WhatsApp.");
        }
    }
    else {
        //console.log('Sharing!');
        navigator.share({ title: title, text: title });
    }

}
function FormatStringForUrl(text) {

    //  console.log("i am called with " + text);
    try {
        if (!text) {
            return text;
        }
        // return text.replace(/[^a-z0-9\s]/gi, '_').replace(/[_\s]/g, '_').replace(/--/g, '_').trim()

        return text.replace(/[^a-z0-9\s]/gi, '-').replace(/[_\s]/g, '-').replace(/ /g, "-").replace(/--/g, '-').trim()
    }
    catch (e) {
    }

    return "";
}
function isNullOrEmpty(value) {

    if (!value || value == "undefined" || null == value || value == "" || value == typeof ("undefined") || $.isEmptyObject(value) || value.length <= 0) {
        return true;
    }

    return false;
}
function clearLocalStorage() {
    try {
        var userid = "";
        var clientType = "";
        var vAppType = "";
        try {
            userid = localStorage.getItem('UserId');
            clientType = localStorage.getItem('validatorclienttype-s');
            vAppType = localStorage.getItem('validatorapptype-s');

        } catch (e) {

        }

        localStorage.clear();
        try {

            localStorage.setItem('validatorclienttype-s', clientType);
            localStorage.setItem('validatorapptype-s', vAppType);
            localStorage.removeItem('UserData');
            localStorage.removeItem('UserRoles');
            localStorage.removeItem('MasterData');
            localStorage.removeItem('LastPSSyncDataTime');
        } catch (e) {
        }
        console.log("start db clear");
    } catch (e) {

    }

    // clearIndexDB();
}

function setLSValueByKey(key, value) {
    var res = JSON.stringify(value);
    localStorage.setItem(key, res);
}

function getLSValueByKey(key) {
    var value = localStorage.getItem(key);
    //  console.log(value);
    return value;
}



function CreateGuid() {
    function _p8(s) {
        var p = (Math.random().toString(16) + "000000000").substr(2, 8);
        return s ? "-" + p.substr(0, 4) + "-" + p.substr(4, 4) : p;
    }

    return _p8() + _p8(true) + _p8(true) + _p8();
}
function HandleConnectionFailure(callback) {
    //console.log("callback");
    //console.log(callback);
    var guid = CreateGuid();
    // Prepare popup buttons why only buttons - at a time we are going to show only one
    var html = ' <input id="' + guid + '" class=" btn-red btn continue-set" style="color: #fff;width: 45%;margin-left:0;margin-right:0;float: left;" type="button" value="retry" >' +
        '<input id="" class="btn-primary btn-red btn continue-set" style="color: #fff;width: 45%;margin-left:0;margin-right:0;float: right;" type="button" value="cancel" onclick="cancelConnectionOverlay()">';
    $(".connection-failed-overlay .reject-app-container").html(html);
    $(".connection-failed-overlay .reject-app-container").on('click', '#' + guid, function () {
        //console.log(callback);
        $(".connection-failed-overlay .reject-app-container").off('click', '#' + guid);
        //console.log("calling from handle connection failue");
        //  CheckandMakeConnectionHealthy(false, function () {
        callback();
        $('.connection-failed-overlay').removeClass("active");
        $(".connection-failed-overlay .reject-app-container").html('');
        // });
    });
    $('.connection-failed-overlay').addClass("active");
}


function IsSignalrConnectionFailed(exception) {
    return connection.state != 1;
}


export function BookAssessment(activity, id, call) {
    try {
        console.log("BookAssessment")
        if (!HandleOffline(function () { BookAssessment(activity, id, call); })) {
            return;
        }       
        var data = PrepareBookAssessment(activity);
        data.CBPI = selfObj.props.currentPersonDetails.Id;
        data.BOSSId = selfObj.props.currentPersonDetails.Id;
        //console.log(JSON.stringify(data));
        var element = document.getElementById(id);
        element.classList.add("is-saving");
        DataCreation(data, function (result, error) {
            if (error) {
                //console.log(error);
                element.classList.remove("is-saving");
                showLockedBox(error.ErrorMsg, "Error", "ok");
                return;
            }
            if (result) {
                if (activity.SCat && activity.SCat.toLowerCase() == "self") {
                    element.classList.remove("is-saving");
                    call(result[0]);
                }
                else {
                    element.classList.remove("is-saving");
                    showLockedBox("Thanks for submitting your request with Pinnacle <br/><br/> Pinnacle care team will call you on your number with in 24 hrs", "Info", "ok");

                }
            }
        },null,"ASSESSMENTINVOICE");

        //CheckandMakeConnectionHealthy(true, function () {
        //    publicHubProxy.invoke('createOrUpdateAssessmentLead', seoName).done(function (result) {

        //        if (result) {

        //            element.classList.remove("is-saving");
        //            call(result);

        //        }
        //        else {
        //            showLockedBox("Thanks for submitting your request with Pinnacle <br/><br/> Pinnacle care team will call you on your number with in 24 hrs", "Info", "ok");

        //        }
        //    }).fail(function (f) {
        //        call("false");
        //        element.classList.remove("is-saving");
        //        if (IsSignalrConnectionFailed(f)) {
        //            HandleConnectionFailure(function () {
        //            });
        //        }
        //        else {
        //            ShowToastMessage(f.message, 2500, "red");
        //        }
        //        //callback(null, f);
        //    });
        //});
    } catch (e) {

    }
}

export function SendOTPtoCustomerForDeactive(mobile, fType, country, callBack) {
    try {
        CheckandMakeConnectionHealthy(true, function () {
            publicHubProxy.invoke('SendOtpToCustomer', mobile, fType, "", "", "", country).done(function (result) {
                // $(".deactivate-container .continue-set").removeClass("changeloadbtn");
                try {
                    if (result) {
                        callBack(result);
                    }

                } catch (e) {
                    console.log(e);
                }
            }).fail(function (f) {
                console.log(f);
                $(".deactivate-container .continue-set").removeClass("changeloadbtn");

                $(".deactivate-error").html(f);
                $(".deactivate-error-cont").addClass("active");

                callBack(null, f);
            });
        });
    } catch (ex) {
        console.log(ex);
        $(".deactivate-container .continue-set").removeClass("changeloadbtn");
    }
}
export function SendOTPtoCustomer(mobile, fType, country, selfInstance) {
    CheckandMakeConnectionHealthy(true, function () {
        selfInstance.setState({ IsSendingOTP: true });
        publicHubProxy.invoke('SendOtpToCustomer', mobile, fType, "", "", "", country).done(function (result) {
            try {
                if (result) {
                    selfInstance.setState({ showOTPBlock: true, IsSendingOTP: false });
                }
            } catch (e) {
                console.log(e);
            }
        }).fail(function (f) {
            console.log(f);
            $(".add-number-error-message").html(f);
            selfInstance.setState({ IsSendingOTP: false });
        });
    });
}

export function validateOTPMethod(email, otp, fType, country, selfInstance, callBack) {
    CheckandMakeConnectionHealthy(true, function () {

        publicHubProxy.invoke('validateOTP', email, otp, fType, "", "", "", country).done(function (result) {
            //console.log(result);
            try {
                $(".add-number-error-message").html("");

                if (!selfInstance.state.showNewEmailAddressBlock) {
                    selfInstance.setState({ showNewEmailAddressBlock: true, IsSendingOTP: false, showOTPBlock: false });
                }

                if (fType == "accountdeactivate" && callBack) {
                    callBack(result);
                    showLockedBox("Your account deactivated successfully.", "", "Ok", false, function () {
                        clearUserRelatedData();
                    });
                }
                else if (result) {
                    showLockedBox("Mobile number changed successfully", "", "Ok", false, function () {
                        clearUserRelatedData();
                    });

                    localStorage.setItem("UserData", JSON.stringify(result.Users[0]));
                    selfInstance.setState({ IsSendingOTP: false });
                    if (selfInstance.callBack) {
                        selfInstance.callBack(result.Users[0]);
                    }
                }
            } catch (e) {
                console.log(e);
            }
        }).fail(function (f) {
            console.log(f);
            selfInstance.setState({ IsSendingOTP: false });

            $(".add-number-error-message").html(f);
            callBack(null, f);
        });
    });
}

export function SaveService(serviceName, id) {
    try {
        var element = document.getElementById(id);
        element.classList.add("is-saving");

        CheckandMakeConnectionHealthy(true, function () {
            publicHubProxy.invoke('enrollToService', serviceName).done(function (result) {

                if (result) {
                    showLockedBox("Thanks for submitting your request with Pinnacle <br/><br/> Pinnacle care team will call you on your number with in 24 hrs", "Info", "ok");

                    element.classList.remove("is-saving");


                }
            }).fail(function (f) {
                element.classList.remove("is-saving");
                if (IsSignalrConnectionFailed(f)) {
                    HandleConnectionFailure(function () {
                    });
                }
                else {
                    ShowToastMessage(f.message, 2500, "red");
                }
              //  callback(null, f);
            });
        });
    } catch (e) {

    }
}

function SaveUserProfileInServer(user, isSignUp, roleId, callback) {
    try {
        //console.log("user profile");

        if (!roleId)
            roleId = "";
          //  selfObj = this;
        CheckandMakeConnectionHealthy(true, function () {
            publicHubProxy.invoke('createOrupdatePersonfromProfile', user, isSignUp).done(function (result) {
                if (result) {

                    localStorage.setItem("currentPersonDetailsToSave", "");
                    if (callback) {
                        callback(result)

                    }
                }
            }).fail(function (f) {
                if (IsSignalrConnectionFailed(f)) {
                    HandleConnectionFailure(function () {
                    });
                }
                else {
                    ShowToastMessage(f.message, 2500, "red");
                }
                callback(null, f);
            });
        });
    } catch (e) {
        console.log("Err::SaveUserProfileInServer()" + e);
    }

}

function getPersonByMobileNumber(callback) {
    try {
        CheckandMakeConnectionHealthy(true, function () {
            //console.log("publicHubProxy");
            //console.log(publicHubProxy);
            publicHubProxy.invoke("getPerson").done(function (result) {
                if (!isNullOrEmpty(result)) {
                    localStorage.setItem("UserData", JSON.stringify(result.User));
                    if (callback) {
                        callback(result.User);
                    }
                }
            }).fail(function (result) {
                console.log(result);
            });
        });

    } catch (e) {

    }
}
function InitiateRazorPay(orderId, orderName, orderAmount, currentOrder, CurrentPersonDetails, selfObj, callback,cancelCallback) {

    var amountToSend = parseFloat(orderAmount);

    var options = {

        "key":
            //test
            //"rzp_test_h98NZ2KfFFm9Us",
        // live
         "rzp_live_LLAGztkLNb3Reo", // Enter the Key ID generated from the Dashboard
        "amount": amountToSend, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        "currency": "INR",
        "name": "Pinnacle Blooms Network",
        "description": orderName,
        "image": "https://pinnacleblooms.org/images/pinnacle-logo-New1-150.png",
        "order_id": orderId, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
        "prefill": {
            "name": CurrentPersonDetails.RDT,
            "email": CurrentPersonDetails.F4,
            "contact": CurrentPersonDetails.F1
        },
        "theme": {
            "color": "#29b37c"
        },
        "handler": function (response) {

            try {
                //if (currentOrder) {
                    //selfObj.OpenPaymentPayLoaderOverlay();
                    //selfObj.PayOrderAmount(callback);
                    if (callback) {
                        callback(amountToSend, response);
                    }
                //}

            } catch (e) {
                console.log("Error in payment section");
                console.log(e)
            }


        },"modal": {
            "ondismiss": function () {
                if (cancelCallback) {
                    cancelCallback();
                }
            }
        }


    };
    var rzp1 = new window.Razorpay(options);
    rzp1.open();
    rzp1.on('payment.failed', function (response) {
       // $(".svg.solid[data-id=" + activity.Id + "]").removeClass("changeloadbtn");
        alert(response.error.code);
        alert(response.error.description);
        alert(response.error.source);
        alert(response.error.step);
        alert(response.error.reason);
        alert(response.error.metadata.order_id);
        alert(response.error.metadata.payment_id);
    });
}
